import request from '@/request'
import { BASE_URL } from '@/config/app'

export const UPLOAD_NEWS_IMAGES = BASE_URL + '/uploadNewsImages'

/**
 * 新闻列表
 * @returns {Promise<AxiosResponse<any>>}
 */
export const newsList = (params) => {
  return request.get('/news', {
    params
  })
}

/**
 * 新闻详情
 * @returns {Promise<AxiosResponse<any>>}
 */
export const newsInfo = (params) => {
  return request.get('/news/info', {
    params
  })
}

/**
 * 新闻创建
 * @returns {Promise<AxiosResponse<any>>}
 */
export const newsCreate = (data) => {
  return request.post('/news/create', data)
}

/**
 * 新闻编辑
 * @returns {Promise<AxiosResponse<any>>}
 */
export const newsEdit = (data) => {
  return request.post('/news/edit', data)
}

/**
 * 新闻删除
 * @returns {Promise<AxiosResponse<any>>}
 */
export const newsDelete = (data) => {
  return request.post('/news/delete', data)
}
